html,body {
  margin: 0;
  /*overflow: hidden;*/
  /*height: 100vh;*/
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
	/*overflow: hidden;*/
	/*height: 100vh;*/
	width: 100vw;
}
a {
  text-decoration: none !important;
  color: #1c8f73;
}
a:hover{
/*  text-decoration: underline !important;
  text-decoration-color: #1c8f73 !important;
  -webkit-text-decoration-color: #1c8f73 !important; /* safari still uses vendor prefix */
  text-decoration: none !important;
}
/*
option:disabled,:selected {
  color: "lightgrey";
}*/

.animate-enter {
  opacity: 0.01;
}

.animate-enter.animate-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.animate-leave {
  opacity: 1;
}

.animate-leave.animate-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

