/* Calendar icons */
.DateRangePicker {
  display: block;
}
.DateRangePickerInput_arrow.DateRangePickerInput_arrow_1 {
  display: none;
}

.DateInput {
  position: relative;
  border-radius: 4px;
  border: 1px solid lightgrey;
  width: 120px;
  height: 30px;
  cursor: pointer;
  background: transparent;
}
.DateInput_screenReaderMessage {
    overflow: visible;
    clip: auto;
    width: 100%;
    pointer-events: none;
		top: 0;    
}
.DateInput_screenReaderMessage:after {
    content: '';
    width: 21px;
    height: 22px;
    position: absolute;
    top: 5px;
    left: 5px;
    background: url('./static/date-range.svg') no-repeat;
    background-size: contain;
}
.DateInput:first-child {
    margin-right: 10px;
}
.DateInput_input:before {
    content: '';
    width: 100px;
    height: 30px;
}
/* Theme styles */


.DateInput__small {
	/*width: 90px;*/
	overflow: hidden;
}
.DayPickerNavigation {
	display: flex;
	justify-content: space-between;
}
.DayPickerNavigation_button {
	display: inline-block;
}
.CalendarDay {
	width: 38px !important;
	height: 38px !important;
	border-radius: 50%;
	color: black;
}
.CalendarDay:focus {
	outline: none;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
	border: none !important;
	background: #448af1 !important;
	color: white;
}
.CalendarDay__selected_span,.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
	background: #e8f1ff;
	border: none;
	color: black;
}
.CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover {
	border: none;
	color: grey;
}
.CalendarDay__default {
	border: none;
}
.CalendarDay__default:hover {
	border: none;
	background: #f5f5f5;
}
.DateRangePicker_picker__directionLeft {
	left: 0px !important;
	top: 40px !important;
}
.DateRangePickerInput {
	overflow: hidden;
	background: transparent;
	border: 0;
}
.DateRangePickerInput__withBorder {
	border-radius: unset;
	margin-bottom: -4px;
}
.DateInput_input {
	/*border-radius: 8px;*/
	padding: 7px 5px;
	padding-left: 30px;
	outline: none;
	background: transparent !important;
}
/*.DateInput_input::placeholder {
	text-align: center;
}
.DateInput_input::-ms-input-placeholder {
	text-align: center;
}*/
.CalendarDay__hovered_span:active {
	color: black;
}
.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
	background: #f5f5f5;
	color: black;
}